import { SupportedLanguages } from "@/enums/internationalization";
import { LangRequest, LangString } from "@/types/api";
import { MainAlertProps } from "@/types/common/alerts";
import { api } from "./api";

export const SmartT = (
  key: string,
  t: (val: string, data?: any) => string,
  data?: { [key: string]: any }
) => {
  const translatedValue = t(key.toLowerCase(), data);

  if (translatedValue === key.toLowerCase()) {
    return (
      key +
      (data
        ? ` - ${Object.keys(data)
            .map((d) => data[d])
            .join(",")}`
        : "")
    );
  }

  return translatedValue;
};

export const ROUTE_LABELS = {
  MISSING: "Oops! Page Not Found",
  DASHBOARD: "Dashboard",
  CONFIRMATION: "Welcome to GAIL",
  CALLS: "Calls",
  MESSAGES: "Messages",
  CONTACTS: "Contacts",
  PARTNERS: "Partners",
  KNOWLEDGE_BASE: "GAIL Brain",
  RECEPTIONIST: "Receptionist",
  SETTINGS: "Settings",
  CAMPAIGNS: "Campaigns",
  CALL_DETAILS: "Call Summary",
  CALL_SCRIPTS: "Scripts",
  SUPPORT_CENTER: "Support Center",
  RELEASE_NOTES: "Features & Releases",
  TRY_GAIL: "Try GAIL",
  START: "Where to Start",
};

export const CONFIRMATION_LABELS = {
  SUBTITLE: "we're so glad you're here",
  SKIP_ONBOARDING: "I Got This (Skip Onboarding Meeting)",
};

export const MISSING_LABELS = {
  SUBTITLE: "Looks like you've ventured too far...",
};

export const DASHBOARD_LABELS = {
  METRIC_TOTAL_LABEL: "Total Calls Handled",
  METRIC_SUPPORT_LABEL: "Support Calls Handled",
  METRIC_SALES_LABEL: "Sales Calls Handled",
  METRIC_AVAILABLE_LINES_LABEL: "Available Lines",
  METRIC_INBOUND_LINES_USED_LABEL: "Inbound Lines Used",
  METRIC_OUTBOUND_LINES_USED_LABEL: "Outbound Lines Used",
};

export const MESSAGE_LABELS = {
  TITLE: "Message Conversations",
  SUBTITLE: "View all the conversations GAIL has had with your customers",
  LOADING: "Loading Messages...",
  NOTHING: "No Messages Found",
};

export const MESSAGE_DETAILS_LABELS = {
  TITLE: "Message Conversation",
  SUBTITLE: "View the conversation between GAIL and your customer",
  LOADING: "Loading Message Conversation...",
};

export const CAMPAIGNS_LABELS = {
  SUBTITLE: "All campaigns managed by GAIL to save you time and money",
  CALLS_IN_QUEUE: "Calls in Queue",
  CALLS_COMPLETED: "Calls Completed",
  TOTAL_CALLS: "Total Calls",
  LOADING_CAMPAIGNS: "Loading Campaigns...",
  LOADING_LEADS: "Loading Contacts...",
  NO_CAMPAIGNS_FOUND: "Sorry, No Campaigns Found",
  NO_CAMPAIGNS: "You Should Create a Campaign!",
  CREATE_CAMPAIGN: "Create Campaign",
  SETUP_CAMPAIGN: "Setup Campaign",
  CREATE_CAMPAIGN_TITLE: "Create Campaign",
  UPDATE_CAMPAIGN_TITLE: "Update Campaign",
  CREATE_CAMPAIGN_SUB_TITLE: "Follow the steps below to create a new Campaign",
  UPDATE_CAMPAIGN_SUB_TITLE: "Make any changes to your Campaign you need",
  CAMPAIGN_DETAILS_TITLE: "Campaign Details",
  CAMPAIGN_DETAILS_SUB_TITLE_RESULTS: "Below are the results of your campaign",
  CAMPAIGN_DETAILS_SUB_TITLE_SCHEDULED: "Start your campaign when you're ready",
  CAMPAIGN_DETAILS_SUB_TITLE_DRAFT: "Please complete your campaign setup",
  UPLOAD_LANGUAGE: {
    uploads: {
      drag_drop: " or drag and drop",
      files_accepted: " files accepted",
    },
    links: {
      click_here: "Click to upload",
    },
  },
  DETAILS: {
    METRICS: {
      TOTAL: "Total Calls",
      CONNECTED: "Connected Calls",
      INTERESTED: "Leads Interested",
      COST: "Campaign Cost",
    },
  },
  TC_MODAL: {
    TITLE: "Agree to T&C",
    SUB_TITLE: "Before you continue, please review GAIL's Terms and Conditions",
    LINK: "Click here to review GAIL's T&C",
    AGREE: "I Agree",
    BACK: "Back",
  },
};

export const CONTACT_LISTS_LABELS = {
  TITLE: "Contact Lists",
  SUBTITLE: "View and manage all the contact lists to be used in campaigns",
  LOADING: "Loading Contact Lists...",
  NOTHING_TITLE: "Oops, its empty!",
  NOTHING_SUBTITLE: "Looks like you haven't added any contact lists yet",
};

export const CONTACT_LIST_DETAILS_LABELS = {
  TITLE: "Contact List Details",
  TITLE_DYNAMIC: "Contact List - ",
  SUBTITLE: "View and manage all the contacts  for this contact list",
  LOADING: "Loading Contact List...",
};

export const TranslateHelper = (
  translateFunc: (key: string) => string,
  key: string | undefined
) => {
  let translatedValue = translateFunc((key || "").toLowerCase());
  if (translatedValue !== (key || "").toLowerCase()) {
    return translatedValue;
  }

  return key || "";
};

export const AutoTranslate = async (
  val: LangString,
  lang: SupportedLanguages,
  token: string | (() => Promise<string | undefined>),
  setAlert: (props: MainAlertProps) => void,
  setIsLoading: (
    isSaving: boolean,
    message: string,
    description?: string
  ) => void,
  t: (key: string) => string
): Promise<LangString> => {
  if (!val[lang]) {
    setAlert({
      status: "error",
      message: t("missingText"),
      secondsToClose: 10,
    });
    return val;
  }

  setIsLoading(true, t("translatingText"));

  const reply = await api<LangRequest, LangString>(
    token,
    "/api/translate",
    "POST",
    {
      value: val[lang] || "",
      lang,
    }
  );

  setIsLoading(false, "");

  if (!reply) {
    setAlert({
      status: "error",
      message: t("translationFailed"),
      secondsToClose: 10,
    });
    return val;
  }

  return reply;
};
