export const ENVIRONMENT_SHORT =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
    ? "PRD"
    : process.env.NEXT_PUBLIC_ENVIRONMENT === "staging"
    ? "STG"
    : "LOC";

export const PUBLIC_ENV_VARS = {
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  GOOGLE_ANALYTICS_MEASUREMENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  ENVIRONMENT_SHORT,
};
